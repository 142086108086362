export const panelData = [
  {
    id: 0,
    imgSrc: `/assets/gallery/img_1.svg`,
  },
  {
    id: 1,
    imgSrc: `/assets/gallery/img_2.svg`,
  },
  {
    id: 2,
    imgSrc: `/assets/gallery/img_3.svg`,
  },
  {
    id: 3,
    imgSrc: `/assets/gallery/img_4.svg`,
  },
  {
    id: 4,
    imgSrc: `/assets/gallery/img_5.svg`,
  },
  {
    id: 5,
    imgSrc: `/assets/gallery/img_6.svg`,
  },
  {
    id: 6,
    imgSrc: `/assets/gallery/img_7.svg`,
  },
  {
    id: 7,
    imgSrc: `/assets/gallery/img_8.svg`,
  },
  {
    id: 8,
    imgSrc: `/assets/gallery/img_9.svg`,
  },
  {
    id: 9,
    imgSrc: `/assets/gallery/img_10.svg`,
  },
  {
    id: 10,
    imgSrc: `/assets/gallery/img_11.svg`,
  },
  {
    id: 11,
    imgSrc: `/assets/gallery/img_12.svg`,
  },
  // {
  //   id: 12,
  //   imgSrc: `/assets/gallery/img_13.svg`,
  // },
  // {
  //   id: 13,
  //   imgSrc: `/assets/gallery/img_14.svg`,
  // },
  // {
  //   id: 14,
  //   imgSrc: `/assets/gallery/img_15.svg`,
  // },
];
